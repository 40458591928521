
// const ReducerContext = createContext(null)

import { createContext, useReducer } from "react";
import { initialState, reducer, DashboardState } from "./reducer";

// export { ReducerContext }
export const StoreContext = createContext<{
    state: DashboardState;
    dispatch: React.Dispatch<any>;
}>({
    state: initialState,
    dispatch: () => null
});


export function StoreProvider({ children }: { children: React.ReactNode}) {
    // initialize & bind reducer
    const [state, dispatch] = useReducer(reducer, initialState);
    
    return (
      <StoreContext.Provider value={{state, dispatch}}>
        {children}
      </StoreContext.Provider>
    )
  }