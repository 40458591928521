import { Box, Button, Stack, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import {JSEncrypt} from 'jsencrypt';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

export interface LoginFormProps {
  handleLogin: (loginToken: string) => void;
}

const host = 'https://uaa.kid-pro.com';

export function LoginForm({handleLogin}: LoginFormProps) {
  const [state, setState] = React.useState({
    account: '',
    password: '',
  });

  useEffect(() => {
    console.log('LoginForm mounted');
  }, []);

  const login = async () =>{
    const data = await fetch(`${host}/api/uaa/auth/public_key`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    }).then((response) => {
      console.log(response);
      return response.json();
    });

    const {id: keyId, key} = data.data;
    const encrypt = new JSEncrypt({
      default_key_size: '512',
    });
    encrypt.setPublicKey(key);

    const encrypted = encrypt.encrypt(
        JSON.stringify({
          redirectUrl: '',
          email: state.account,
          password: state.password,
        })
    );
    if(!encrypted){
      return;
    }

    const loginData = await fetch(`${host}/api/uaa/login_crypto`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-crypt-key-id': keyId,
      },
      body: JSON.stringify({
        data: encrypted,
      }),
    }).then((response) => {
      console.log(response);
      return response.json();
    });

    const loginToken = loginData.data.loginToken;
    localStorage.setItem('loginToken', loginToken);
    handleLogin(loginToken)
  }

  return <Box
    component="form"
    sx={{
      '& .MuiTextField-root': { m: 1, width: '25ch' },
    }}
    noValidate
    autoComplete="off"
>
  <Stack spacing={2}>
    <div>
      <TextField
        id="account"
        label="Account"
        // defaultValue=""
        value={state.account}
        onChange={(e) => {
          setState({
            ...state,
            account: e.target.value,
          });
        }}
      />
      <TextField
        id="outlined-password-input"
        label="Password"
        type="password"
        autoComplete="current-password"
        value={state.password}
        onChange={(e) => {
          setState({
            ...state,
            password: e.target.value,
          });
        }}
      />
    </div>
    <div>
      <Button 
        variant="contained" 
        onClick={async () => {await login() }}
      >SignIn</Button>
    </div>

  </Stack>

  </Box>
}
