import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DashboardDataState } from '../../reducer';
import { TableFooter, TablePagination, TextField, Button } from '@mui/material';

export interface EventDashboardProps {
  prop?: DashboardDataState;
  page: number;
  rowsPerPage: number;
  count: number;
  search: string;
  handleDetailClick: (id: string) => void;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EventDashboard = React.memo(eventDashboard);

function eventDashboard({prop = {
    rowNames: [],
    rows: []
  }, 
  page,
  rowsPerPage,
  count,
  search,
  handleSearch,
  handleDetailClick, 
  handleChangePage,
  handleChangeRowsPerPage
}: EventDashboardProps) {
  return (
    
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <MyPagination
              page={page}
              rowsPerPage={rowsPerPage}
              count={count}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              onSearch={handleSearch}
              search={search}
          />
          <TableRow>
            {
              prop.rowNames.map((rowName) => (
                <TableCell key={rowName + 'title'}>{rowName}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Array.isArray(prop.rows) &&
            prop.rows.map((row) => (
              <TableRow
                onClick={() => handleDetailClick(row.id)}
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell >{row.name}</TableCell>
                <TableCell >{row.email}</TableCell>
                <TableCell >{row.phone}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
        <TableFooter>
         <MyPagination
            page={page}
            rowsPerPage={rowsPerPage}
            count={count}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            onSearch={handleSearch}
            search={search}
         />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

const MyPagination = ({
  page,
  rowsPerPage,
  count,
  search,
  handleChangePage,
  handleChangeRowsPerPage,
  onSearch,
}: {
  page: number;
  rowsPerPage: number;
  count: number;
  search: string;
  handleChangePage: (newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    
  return <TableRow>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      colSpan={0}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page',
        },
        native: true,
      }}
      onPageChange={(event, page) => {
        handleChangePage(page);
      }}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
      <TextField
        id="search"
        label="search"
        value={search}
        onChange={onSearch}
      /> 
  </TableRow>
}

