import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DetailData } from '../../reducer';
import { Box } from '@mui/system';

export interface EventDetailDialogProps {
  open: boolean;
  onClose: (value: string) => void;  
  data: DetailData | null;
}

export function EventDetailDialog({
  onClose,
  open,
  data = null,
}: EventDetailDialogProps) {
  const handleClose = () => {
    onClose('');
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Detail</DialogTitle>
      <div>
        {
          data &&
          Object.entries(data).map(([key, val]) => (
            <div key={key}>
              <span>{key} : </span>
              {
                typeof val !== 'object' ?
                <span>
                  {
                    key === 'url'?
                    <a href={val}>{val}</a>:
                    <span>{val.toString()}</span>
                  }
                </span>: ''
              }
            </div>
          ))
        }
        <div>
          {
            data?.line &&
            Object.entries(data.line).map(([key, val]) => (
              <div key={key}>
                <span>line {key} : </span>

                <span>{
                  key === 'pictureUrl'? 
                  <Box
                  component="img"
                  sx={{
                    height: 233,
                    width: 350,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt="The house from the offer."
                  src={val}
                />                  

                  : val  
                }</span>
              </div>
            ))
          }
        </div>
      </div>
    </Dialog>
  );
}
