import React, { useContext, useEffect, useReducer, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import {EventDashboard} from './components/EventDashboard/EventDashboard';
import {LoginForm} from './components/LoginForm/LoginForm';
import {FilterBar} from './components/FilterBar/FilterBar';
import { DashboardAction, DashboardActionType, FetchType } from './reducer';
import { StoreContext, StoreProvider } from './reducerProvider';
import Button from '@mui/material/Button';
import { EventDetailDialog } from './components/EventDetailDialog';
import { Container } from '@mui/material';

const baseUrl = 'https://marketing.kid-pro.com';

function App() {
  const {state, dispatch} = useContext(StoreContext);
  // login token
  const [loginToken, setLoginToken] = useState<null | string>(null);
  
  // dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (userId: string) => {
    dispatch(new DashboardAction(DashboardActionType.FETCH_DETAIL_DATA_START, userId));
    setTimeout(() => setOpen(true),30);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  useEffect(() => {
    localStorage.getItem('loginToken') && setLoginToken(localStorage.getItem('loginToken'));
  }, []);

  useEffect(() => {
    if(!loginToken) return;
    dispatch(new DashboardAction(DashboardActionType.FETCH_DATA_START, null));
  }, [loginToken]);

  useEffect(() => {
    if(!state.isLoading) return;
    
    switch(state.fetchType) {
      case FetchType.LIST:
        fetch(`${baseUrl}/api/marketing/v1/backstage/event/share-yoga/users?email=${state.filter.email}&limit=${state.filter.limit}&offset=${state.filter.offset}`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Beare ${loginToken}`, 
          }
        })
          .then(res => res.json())
          .then(res => {
            dispatch(new DashboardAction(DashboardActionType.FETCH_DATA_SUCCESS, res));
          })
          .catch(err => {
            dispatch(new DashboardAction(DashboardActionType.FETCH_DATA_ERROR, err));
          });
        break;
      case FetchType.DETAIL:
        if(state.currUserId === null){
          console.error('currUserId is null');
          return;
        }
        fetch(`${baseUrl}/api/marketing/v1/backstage/event/share-yoga/users/${state.currUserId}`,{
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Beare ${loginToken}`,
          }
        })
          .then(res => res.json())
          .then(res => {
            dispatch(new DashboardAction(DashboardActionType.FETCH_DETAIL_DATA_SUCCESS, res));
          })
          .catch(err => {
            dispatch(new DashboardAction(DashboardActionType.FETCH_DETAIL_DATA_ERROR, err));
          });
        break;
    }
  }, [state.isLoading]);

  return (
    <div className="App">
      <Container maxWidth="lg">
        {
          loginToken ? 
          <EventDashboard 
            prop={state.data} 
            page={state.filter.page}
            rowsPerPage={state.filter.limit}
            count={state.filter.total}
            handleDetailClick={handleClickOpen} 
            handleChangePage={(page) => {
              dispatch(new DashboardAction(DashboardActionType.CHANGE_PAGE, page))
              dispatch(new DashboardAction(DashboardActionType.FETCH_DATA_START, null));
            }}
            handleChangeRowsPerPage={(e) => {
              dispatch(new DashboardAction(DashboardActionType.CHANGE_LIMIT, parseInt(e.target.value, 10)))
              dispatch(new DashboardAction(DashboardActionType.FETCH_DATA_START, null));
            }}
            search={state.filter.email}
            handleSearch={(e) => {
              dispatch(new DashboardAction(DashboardActionType.SEACH_EMAIL, e.target.value));
              dispatch(new DashboardAction(DashboardActionType.FETCH_DATA_START, null));
            }}
          />             
          :
          <LoginForm handleLogin={setLoginToken} />
        }

      </Container>

      <EventDetailDialog
        open={open}
        onClose={handleClose}
        data={state.detailData}
      />        
    </div>
  );
}

export default App;
