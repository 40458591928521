export interface DashboardDataState {
    rowNames: string[];
    rows: {
      id: string;
      name: string;
      email: string;
      phone: string;
    }[];
}

export enum DashboardActionType {
    CHANGE_PAGE = 'CHANGE_PAGE',
    CHANGE_LIMIT = 'CHANGE_LIMIT',
    SEACH_EMAIL = 'SEACH_EMAIL',
    FETCH_DATA_START = 'FETCH_DATA_START',
    FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS',
    FETCH_DATA_ERROR = 'FETCH_DATA_ERROR',
    FETCH_DETAIL_DATA_START = 'FETCH_DETAIL_DATA_START',
    FETCH_DETAIL_DATA_SUCCESS = 'FETCH_DETAIL_DATA_SUCCESS',
    FETCH_DETAIL_DATA_ERROR = 'FETCH_DETAIL_DATA_ERROR',
}
  
export class DashboardAction {
    type: DashboardActionType;
    payload: any;

    constructor(type: DashboardActionType, payload: any) {
      this.type = type;
      this.payload = payload;
    }
}

export enum FetchType {
    LIST = 'list',
    DETAIL = 'detail',
}

export interface DashboardState {
    isLoading: boolean;
    data: DashboardDataState;
    detailData: DetailData | null;
    fetchType: FetchType | null;
    filter: {
        total: number;
        email: string;
        offset: number;
        limit: number;
        page: number;
    };
    currUserId: string | null; 
}

export const initialState: DashboardState = {
    isLoading: false,
    fetchType: null,
    data: {
      rowNames: [
        'Id',
        'Name',
        'Email',
        'Phone',
      ],
      rows: [{
          id: '',
          name: '',
          email: '',
          phone: '',
      }]
    },  
    filter: {
      total: 0,
      email: '',
      offset: 0,
      limit: 10,
      page: 0,
    },
    currUserId: null,
    detailData: null
  };


export const reducer = (state: typeof initialState, action: DashboardAction) => {
    switch (action.type) {
      case DashboardActionType.CHANGE_PAGE:
          return { 
            ...state, 
            filter: {
              ...state.filter,
              offset: action.payload * state.filter.limit,
              page: action.payload,
            } 
          };  
      case DashboardActionType.CHANGE_LIMIT:
          return { 
            ...state, 
            filter: {
              ...state.filter,
              offset: 0,
              page: 0,
              limit: action.payload,
            } 
          };      
      case DashboardActionType.SEACH_EMAIL:
          return {
            ...state,
            filter: {
              ...state.filter,
              email: action.payload,
            }
          };         
      case DashboardActionType.FETCH_DATA_START:
          return { 
            ...state, 
            isLoading: true, 
            fetchType: FetchType.LIST,
          };
      case DashboardActionType.FETCH_DATA_SUCCESS:
          return { ...state, 
            isLoading: false,
            fetchType: null,
            data: {
              ...state.data,
              rows: action.payload.data,
            },
            filter: {
              ...state.filter,
              total: action.payload.total,
            }
          };
      case DashboardActionType.FETCH_DATA_ERROR:
          return { 
            ...state, 
            isLoading: false, 
            fetchType: null, 
            error: action.payload 
          };
      case DashboardActionType.FETCH_DETAIL_DATA_START:
          return { 
            ...state, 
            isLoading: true, 
            fetchType: FetchType.DETAIL, 
            currUserId: action.payload,
          };
      case DashboardActionType.FETCH_DETAIL_DATA_SUCCESS:
          return { 
            ...state, 
            isLoading: false, 
            fetchType: null,
            detailData: action.payload,
          };
      case DashboardActionType.FETCH_DETAIL_DATA_ERROR:
          return { 
            ...state, 
            isLoading: false, 
            fetchType: null, 
            error: action.payload,
            currUserId: null,
          };          
    }
};

export interface DetailData {
  couponCreated: boolean;
  email: string;
  fbUid: string;
  goodCount: number;
  id: string;
  isShareCode: boolean;
  isTag: boolean;
  line: {
    displayName: string;
    language: string;
    userId: string;
  };
  lineId: string;
  name: string;
  phone: string;
  process: number;
  processDone: boolean;
  processState: string;
  url: string;
  _id: string;
}
